import globals from "../globals.js"
export default {
    data() {
        return {
            ajax_call_in_progress: false,
        }
    },
    methods:{

        createInvestors(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/consultancy/investor/groups", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        // this.warning_msg = response.data.reason
                        // this.$refs.warning_modal.open();
                        // setTimeout(() => {
                        //     this.$refs.warning_modal.close();
                        // }, 3000);
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getInvestorstListsData(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/consultancy/investor/groups?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&company_id=${params.company_id}&group_type=${params.group_type}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getInvestorsById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + "/consultancy/investor/groups/" + id)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                    this.ajax_call_in_progress = false;
                    this.stringified_response = JSON.stringify(this.create_data)
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        editInvestors(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + "/consultancy/investor/groups/" + id, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                }
                catch(error){
                    reject(error.response.data.reason)
                }
            })
        },
        deleteInvestors(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + `/consultancy/investor/groups/delete`, {
                        data: params
                    })
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
    }
}